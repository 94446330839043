<template>
  <v-card
    flat
    v-if="packages && packages.length > 1"
    color="white"
    class="packages-type-list-container mt-5 mt-md-2"
  >
    <v-card-title
      class="text-h3 secondary--text font-weight-bold text--lighten-1 pb-1"
    >
      {{ $t("checkout.packageAddTitle") }}
    </v-card-title>
    <v-card-text class="text-body-2 py-0">
      {{ $t("checkout.packageAddDescr") }}
    </v-card-text>

    <v-card-actions>
      <v-radio-group
        class="w-100 d-flex flex-column"
        mandatory
        v-model="packagingTypeId"
        row
      >
        <v-radio
          v-for="pck in packages"
          :key="pck.productId"
          :value="'' + pck.productId"
          color="default"
          class="w-100 mb-3"
        >
          <template v-slot:label>
            <strong class="package-name ml-2">{{ pck.name }}</strong
            ><span class="package-description" v-if="pck.description"
              >: {{ pck.description }}</span
            >
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-actions>
  </v-card>
</template>

<script>
import cartService from "~/service/cartService";
import { mapCartInfo } from "~/service/ebsn";
export default {
  name: "PackagesTypeList",
  data() {
    return { packages: [] };
  },
  computed: {
    ...mapCartInfo({
      packagingTypeId: "package_type"
    })
  },
  methods: {
    async fetchPackages() {
      this.packages = await cartService.getPackages();
    }
  },
  mounted() {
    this.fetchPackages();
  }
};
</script>
