<template>
  <v-card
    rounded="sm"
    :href="link"
    @click.prevent="handleLink"
    elevation="0"
    class="proposal-img"
  >
    <v-img :src="src" :alt="proposal.descr" max-height="731px">
      <h3
        v-if="proposal.descr"
        class="img-label"
        :style="{
          color:
            proposal.metaData &&
            proposal.metaData.category_proposal_type &&
            proposal.metaData.category_proposal_type.COLOR
        }"
        :class="[
          proposal.metaData &&
            proposal.metaData.category_proposal_type &&
            proposal.metaData.category_proposal_type.TEXT_POSITION,
          proposal.metaData &&
            proposal.metaData.category_proposal_type &&
            proposal.metaData.category_proposal_type.TEXT_BORDER
        ]"
      >
        {{ proposal.descr }}
      </h3>
    </v-img>
  </v-card>
</template>
<style scoped lang="scss">
.proposal-img {
  position: relative;
}
.img-label {
  font-size: 38px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  line-height: 1;
  padding: 8px;
  @media #{map-get($display-breakpoints, 'sm-only')} {
    font-size: 26px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 18px;
  }
  &.top {
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
  &.bottom {
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
  &.bottom-left {
    bottom: 0;
    left: 0;
    text-align: left;
  }
  &.bottom-right {
    bottom: 0;
    right: 0;
    text-align: right;
  }
  &.top-left {
    top: 0;
    left: 0;
    text-align: left;
  }
  &.top-right {
    top: 0;
    right: 0;
    text-align: right;
  }
  &.border-bottom {
    border-bottom: 2px solid #fff;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px 8px 15px 8px;
  }
  &.border-top {
    border-top: 2px solid #fff;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px 8px 15px 8px;
  }
}
</style>
<script>
import get from "lodash/get";
import banner from "~/mixins/banner";
export default {
  name: "ProposalImage",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    src() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
