var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-card',{staticClass:"pa-3 mt-4",staticStyle:{"min-height":"110px"},attrs:{"flat":"","to":{
        name: 'Page',
        params: { pageName: 'spese-di-spedizione' }
      },"target":"_blank"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"secondary","left":""}},[_vm._v("$fastDelivery")]),_c('div',{staticClass:"text-caption black--text"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("payment.infoBox.service.title"))+" ")]),_c('div',[_vm._v(_vm._s(_vm.$t("payment.infoBox.service.text")))])])],1)],1),_c('v-card',{staticClass:"pa-3 mt-4",staticStyle:{"min-height":"127px"},attrs:{"flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"secondary","left":""}},[_vm._v("$supportAgent")]),_c('div',{staticClass:"text-caption black--text"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("payment.infoBox.contactUs.title"))+" ")]),_c('div',[_vm._v(_vm._s(_vm.$t("payment.infoBox.contactUs.text")))])])],1),_c('v-card-actions',{staticClass:"mt-2"},[_c('v-btn',{staticClass:"mx-auto font-weight-normal",attrs:{"outlined":"","height":"35","width":"210","color":"secondary","large":"","to":{
            name: 'Page',
            params: { pageName: 'contatti' }
          }}},[_vm._v(" "+_vm._s(_vm.$t("payment.infoBox.contactUs.button"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }