var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"proposal-img",attrs:{"rounded":"sm","href":_vm.link,"elevation":"0"},on:{"click":function($event){$event.preventDefault();return _vm.handleLink.apply(null, arguments)}}},[_c('v-img',{attrs:{"src":_vm.src,"alt":_vm.proposal.descr,"max-height":"731px"}},[(_vm.proposal.descr)?_c('h3',{staticClass:"img-label",class:[
        _vm.proposal.metaData &&
          _vm.proposal.metaData.category_proposal_type &&
          _vm.proposal.metaData.category_proposal_type.TEXT_POSITION,
        _vm.proposal.metaData &&
          _vm.proposal.metaData.category_proposal_type &&
          _vm.proposal.metaData.category_proposal_type.TEXT_BORDER
      ],style:({
        color:
          _vm.proposal.metaData &&
          _vm.proposal.metaData.category_proposal_type &&
          _vm.proposal.metaData.category_proposal_type.COLOR
      })},[_vm._v(" "+_vm._s(_vm.proposal.descr)+" ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }