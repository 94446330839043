<template>
  <div class="payment">
    <v-container class="d-flex flex-column scroll-container">
      <CategoryTitle :category="category" class="px-0" />

      <v-row>
        <v-col cols="12" md="8" order="last" order-sm="first">
          <v-row no-gutters>
            <div
              class="d-flex w-100 flex-column py-5 px-3 white mb-3 rounded-md"
            >
              <v-row no-gutters justify="space-between">
                <v-col cols="12" sm="4" class="pr-0 pr-sm-3 mb-3 mb-sm-0">
                  <CartInfoServiceCard
                    :shippingAddress="cart.shippingAddress"
                    :editable="false"
                    @edit="openAddressSelector"
                    class="grey lighten-2"
                  />
                </v-col>
                <v-col cols="12" sm="4" class="pr-0 pr-sm-3 mb-3 mb-sm-0">
                  <CartInfoAddressCard
                    :shippingAddress="cart.shippingAddress"
                    :editable="false"
                    @edit="openAddressSelector"
                    class="grey lighten-2"
                  />
                </v-col>
                <v-col cols="12" sm="4" class="pr-0 pr-sm-3 mb-3 mb-sm-0">
                  <CartInfoTimeslotCard
                    :shippingAddress="cart.shippingAddress"
                    :timeslot="cart.timeslot"
                    :editable="false"
                    @edit="openTimeslotSelector"
                    class="grey lighten-2"
                  />
                </v-col>
              </v-row>
            </div>
          </v-row>

          <!-- <PaymentInvoice @billingFormCompleted="billingFormCompleted" /> -->

          <CheckoutSummary :orderCart="cart" class="d-block d-md-none" />

          <PackagesTypeList />

          <TicketTypeList
            :cart="cart"
            mode="cart"
            :isPaymentDisabled="!billingFormCompletedInternal && invoiceNeeded"
          />

          <PaymentTypeList
            :cart="cart"
            mode="cart"
            :isPaymentDisabled="!billingFormCompletedInternal && invoiceNeeded"
            :options="paymentTypeListOptions"
          />
          <PaymentInfoBoxes class="d-flex d-md-none" />

          <RecaptchaDisclaimer @click="clicked" />
        </v-col>
        <!-- :cartInfos="cartInfos" -->
        <v-col
          cols="12"
          md="4"
          order="first"
          order-sm="last"
          v-if="cart.shippingAddress"
        >
          <CheckoutSummary
            :isCheckout="false"
            :orderCart="cart"
            class="d-none d-md-block"
          />

          <PaymentInfoBoxes class="d-none d-md-block" />
        </v-col>
      </v-row>
    </v-container>
    <v-spacer class="spacer-scroll"></v-spacer>
    <v-container fluid v-if="getProposals('footer-banner')">
      <ProposalSlider :proposals="getProposals('footer-banner')" />
    </v-container>
  </div>
</template>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CartInfoServiceCard from "@/components/cart/CartInfoServiceCard.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import TicketTypeList from "@/components/payment/TicketTypeList.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import PaymentInfoBoxes from "@/components/payment/PaymentInfoBoxes.vue";
// import PaymentInvoice from "@/components/payment/PaymentInvoice.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";
import PackagesTypeList from "@/components/payment/PackagesTypeList.vue";

import login from "~/mixins/login";
import productMixins from "~/mixins/product";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";

import { mapCartInfo } from "~/service/ebsn";
import AnalyticsService from "~/service/analyticsService";

import { mapState } from "vuex";

export default {
  name: "Payment",
  mixins: [login, productMixins, cartInfo, categoryMixins, clickHandler],
  components: {
    PaymentTypeList,
    TicketTypeList,
    CategoryTitle,
    CheckoutSummary,
    PaymentInfoBoxes,
    // PaymentInvoice,
    ProposalSlider,
    CartInfoServiceCard,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    RecaptchaDisclaimer,
    PackagesTypeList
  },
  data() {
    return {
      paymentTypeList: [],
      isPaymentDisabled: false,
      billingFormCompletedInternal: false,
      paymentTypeListOptions: {
        rememberCardDefaultValue: true,
        flatPanels: true,
        showCardLogos: true,
        hidePrevButton: true
      }
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapCartInfo({ needInvoice: "need_invoice" }),
    invoiceNeeded() {
      return this.needInvoice === "1" ? true : false;
    }
  },
  methods: {
    billingFormCompleted(completed) {
      this.billingFormCompletedInternal = completed;
    }
  },
  created() {
    // this.fetchPackages();

    AnalyticsService.checkoutProgress(this.cart);

    // this.getUserDetails();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.cart && vm.cart.totalItems > 0) {
        next();
      } else {
        next("/");
      }
    });
  }
};
</script>
