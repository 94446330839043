<template>
  <div class="payment-methods" v-if="ticketTypeList.length > 0">
    <h2 class="payment-panels-title">Piattaforme Partner</h2>
    <v-expansion-panels
      flat
      accordion
      v-model="selectedPayment"
      :disabled="isPaymentDisabled || ticketSelectorLocked"
      :value="selectedPayment"
    >
      <v-expansion-panel
        v-for="ticketType in ticketTypeList"
        v-bind:key="ticketType.paymentTypeId"
        @click="toggleTicketType(ticketType)"
      >
        <v-expansion-panel-header hide-actions v-on:click.prevent="() => {}">
          <!-- contenitore generale degli elementi della label -->
          <label
            :for="'test' + ticketType.paymentTypeId"
            class="d-flex flex-row"
          >
            <!-- nome del tipo di pagamento -->
            <div
              class="d-flex flex-column meal-ticket-heading-wrapper align-center"
            >
              <span class="text-uppercase"
                >{{ ticketType.paymentType.paymentName }}
              </span>
              <span>
                <v-img
                  contain
                  class="logo-heading-image"
                  :src="
                    '/img_layout/meal_ticket/' +
                      ticketType.paymentType.paymentTypeId +
                      '.svg'
                  "
                  :alt="'Logo' + ticketType.paymentType.paymentName"
                />
              </span>
            </div>
          </label>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div :class="{ 'payment-disabled': isPaymentDisabled }">
            <GatewayWriter
              :payment-type="ticketType.paymentType"
              :order-id="orderId"
              :component-name="ticketType.paymentType.gatewayClient"
              :cartInfos="cartInfos"
              :mode="mode"
              :acceptConditions="acceptConditions"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<style scoped lang="scss">
.meal-ticket-heading-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row !important;
  .logo-heading-image {
    width: 120px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 100px;
    }
  }
  .text-uppercase {
    flex-grow: 1;
  }
}
</style>
<script>
import GatewayWriter from "~/components/payment/gateways/GatewayWriter.vue";

import MealTicketService from "~/service/mealTicketService";

export default {
  name: "TicketTypeList",
  props: {
    orderId: { type: Number, required: false },
    mode: { type: String, required: true },
    cartInfos: { type: Object },
    isPaymentDisabled: { type: Boolean, default: false },
    acceptConditions: { type: Boolean, default: false }
  },
  components: { GatewayWriter },
  data() {
    return {
      selectedPayment: null,
      ticketSelectorLocked: false,
      ticketTypeList: []
    };
  },
  methods: {
    async fetchTicketTypeList() {
      this.ticketTypeList = await MealTicketService.list();
      this.ticketSelectorLocked = false;
      for (var i = 0; i < this.ticketTypeList.length; i++) {
        if (this.ticketTypeList[i].giftCertificateConfirmed == true) {
          this.ticketSelectorLocked = true;
        }

        if (
          this.ticketTypeList[i].logged == true &&
          this.acceptConditions == false
        ) {
          this.$emit("toggleAcceptConditions");
        }
        if (this.ticketTypeList[i].selected) {
          this.selectedPayment = i;
          break;
        }
      }
    },
    async toggleTicketType(ticketType) {
      let ticketTypes = await MealTicketService.select(
        ticketType.paymentType.paymentTypeId,
        !ticketType.selected
      );
      this.ticketTypeList = ticketTypes;
    }
  },
  mounted() {
    this.fetchTicketTypeList();
  },
  watch: {
    isPaymentDisabled(isDisabled) {
      if (isDisabled) this.selectedPayment = null;
    }
  }
};
</script>
